.componentImage {
    &_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &_uploader {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 250px;
        height: 200px;
        border: 1px dashed var(--kt-input-border-color);
        border-radius: 0.475rem;
        background-color: transparent;
        transition: border-color 0.3s;
        margin-bottom: .5rem;

        &.active {
            border-color: var(--kt-primary);
        }

        & .carousel {
            height: 100%;
            width: 100%;

            &.fullscreen {
                position: fixed;
                top: 0;
                left: 0;
                background-color: rgba($color: #000000, $alpha: 0.7);
                z-index: 9999;

                & .carousel-indicators {
                    transform: translateY(0);
                }

            }

            & .carousel-indicators {
                transform: translateY(100%);
                padding: 10px 0;
                margin-bottom: 0;

                &>button {
                    height: 10px;
                    width: 10px;
                    border: none;
                    border-radius: 4rem;
                    background-color: var(--kt-primary);
                }
            }

            & .carousel-inner {
                height: 100%;
            }

            & .carousel-item {
                height: 100%;
            }

        }

        &:has(.carousel) {
            justify-content: center;
        }

        &:has(.carousel-indicators) {
            margin-bottom: 1.875rem;
        }
    }

    &_carouselContainer {
        position: relative;
    }

    &_imageContainer {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    &_imageToolbar {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        align-items: center;

        &>*:not(:last-child) {
            margin-right: 1rem;
        }
    }

    &_image {
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        cursor: pointer;
    }

    &_uploadMoreButton {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        border-radius: 4rem;
        height: 25px;
        width: 25px;
        z-index: 1;
    }

    &_uploaderButton {
        border: 1px solid var(--kt-input-border-color);
        border-radius: 4rem;
        background-color: transparent;
        width: 100px;
        height: 100px;
        transition: all 0.3s;
        color: var(--kt-text-muted);

        & .svg-icon {
            color: inherit;

            & svg {
                transition: fill 0.3s;
                fill: currentColor;
                width: 50px;
                height: 50px;
            }
        }

        &:hover {
            background-color: var(--kt-input-border-color);
            color: var(--kt-card-bg);
        }
    }

    &_uploaderText {
        margin-top: 10px;
        font-size: 12px;
        user-select: none;
        text-align: center;
        color: var(--kt-text-muted);
    }

    &_props {
        max-width: 250px;
        font-size: 0.85rem;
        color: var(--kt-text-muted);
        text-align: center;

        &:not(:last-child) {
            margin-bottom: .5rem;
        }
    }

    &_allowedFormat,
    &_maxSize {
        font-weight: 600;
        color: var(--kt-text-dark);
    }

    &_editor {
        width: 100%;
    }

    &_editorToolbar {
        margin-top: 1rem;
        display: flex;
        align-items: center;
    }

    &_editorScale {
        flex-grow: 1;
        margin-right: 1rem;
    }
}