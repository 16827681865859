.componentPhone {
    flex-grow: 1;

    &.withButton {
        margin-right: 1rem;
    }

    &_container {
        display: flex;
        align-items: center;
    }

    &_button {
        flex-shrink: 0;
    }
}