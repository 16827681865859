[data-app-theme=theme_2][data-theme=dark] #kt_app_body {
    --kt-app-bg-color: #4c404e;
    --app-border-color: #6b646f;
    --kt-border-color: var(--app-border-color);
    --app-text-color: #f6f4f5;
    --kt-input-solid-bg: #443a46;
    --kt-input-solid-bg-focus: var(--kt-input-solid-bg);
    --kt-text-muted: #808080;
    --kt-light: #6c5c70;
    --kt-dark-light: var(--app-layout-bg-color);
    --kt-light-inverse: var(--app-text-color);
    --kt-light-active: var(--kt-dark-light);
    --kt-form-check-label-color: var(--kt-text-muted);
    --kt-form-check-label-color-checked: var(--app-text-color);

    --kt-dropdown-bg: var(--kt-app-bg-color);
    --kt-menu-dropdown-bg-color: var(--kt-dropdown-bg);
   
    --kt-gray-100: var(--app-text-color);
    --kt-gray-200: var(--app-text-color);
    --kt-gray-300: var(--app-text-color);
    --kt-gray-400: var(--app-text-color);
    --kt-gray-500: var(--app-text-color);
    --kt-gray-600: var(--app-text-color);
    --kt-gray-700: var(--app-text-color);
    --kt-gray-800: var(--app-text-color);
    --kt-gray-900: var(--app-text-color);

    --kt-text-gray-100: var(--app-text-color);
    --kt-text-gray-200: var(--app-text-color);
    --kt-text-gray-300: var(--app-text-color);
    --kt-text-gray-400: var(--app-text-color);
    --kt-text-gray-500: var(--app-text-color);
    --kt-text-gray-600: var(--app-text-color);
    --kt-text-gray-700: var(--app-text-color);
    --kt-text-gray-800: var(--app-text-color);
    --kt-text-gray-900: var(--app-text-color);


    & .componentButton {
        &.secondary {
            background-color: var(--kt-light);
            color: var(--kt-light-inverse);
            &:hover {
                background-color: var(--kt-light-active);
            }
        }
        &.moduleList_actionButton {
            background-color: var(--kt-light);
            color: var(--kt-light-inverse);
            &:hover {
                background-color: var(--kt-primary-active);
                color: var(--kt-primary-inverse);
            }
        }
    }

    & .componentFilters {
        &_dropdownFilter {
            --kt-input-solid-bg: #3e3540;
        }
        &_stringButton.componentButton {
            background-color: var(--kt-app-bg-color);
            border-color: var(--app-border-color);
            &:hover {
                background-color: var(--kt-primary-active);
            }
        }
    }
 

    & .moduleList {
        & .table {
            & thead tr {
                --kt-text-muted: var(--app-text-color);
            }
        }
    }

    & .moduleSchedule {
        &_cell {
            &.available {
                background-color: var(--app-layout-bg-color);
                &:hover {
                    background-color: var(--kt-app-bg-color);
                }
            }
            &.disabled {
                background-color: var(--app-layout-bg-color);
            }
        }
    }


    & .nav-tabs .nav-link.active {
        color: var(--app-text-color);

    }

    & .progress {
        --kt-progress-bg: var(--kt-input-solid-bg);
    }

    & input::placeholder {
        color: var(--kt-text-muted);
    }
}