.componentInfoStrings {
    flex-grow: 1;

    &_list {
        display: flex;
        flex-direction: column;
    }

    &_container {
        display: flex;
        user-select: none;

        &:not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }

    &_counter {
        display: flex;
        align-items: center;
        width: unset;
        margin-left: 0.5rem;
        flex-shrink: 0;
    }

    &.clickable {

        cursor: pointer;
        transition: color 0.3s, background-color 0.3s;

        &:hover {
            background-color: var(--kt-primary);
            color: var(--kt-primary-inverse);
        }
    }

}