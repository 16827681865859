.componentDashboard {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 1rem;
    height: 41px; //фиксированная высота нужна для заполнения страницы графиком

    &.inverse {
        justify-content: unset;
    }

    &>*:not(:last-child) {
        margin-right: 10px;
    }
}