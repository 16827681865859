.moduleYandexMap {
    & .ymaps-2-1-79-map-copyrights-promo {
        display: none;
    }

    ;

    & .ymaps-2-1-79-copyright {
        display: none;
    }
}