.moduleLinksBlock {
    margin-bottom: 15px;

    &_title {
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 16px;
    }

    &_blocksContainer {
        display: flex;
        flex-wrap: wrap;
    }

    &_blockContainer {
        width: 25%;
        padding-right: 10px;
        margin-bottom: 5px;
    }

    &_block {
        position: relative;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--kt-card-border-color);
        border-radius: 0.625rem;
        background-color: var(--kt-card-bg);
        padding: 32px 16px;
        color: var(--kt-gray-500);
        box-shadow: 0 1px 15px #0000000a, 0 1px 6px #0000000a;
        cursor: pointer;
        transition: bottom 0.5s;
    }

    &_blockIcon {
        color: var(--kt-gray-700);
        transition: color 0.3s;

        & svg {
            fill: currentColor;
            width: 75px;
            height: 75px;
            margin-bottom: 5px;
        }
    }

    &_blockTitle {
        color: inherit;
        transition: color 0.3s;
        font-size: 1rem;
        font-weight: 600;
        user-select: none;
    }
}