.moduleInfo {
    &_block {
        margin-bottom: 10px;
    }

    &_area {
        padding: 0 calc(var(--bs-gutter-x) * 0.5);
        margin-bottom: 10px;
    }

    &_fields {
        display: flex;
        margin-top: 10px;
        flex-wrap: wrap;
    }

    &_field {
        display: flex;
        flex-wrap: wrap;

        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }

        &_label {
            width: 100%;
            font-size: 13px;
            font-weight: 600;
            line-height: 1.5;
            color: var(--kt-text-muted);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

}