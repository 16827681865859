.moduleList {

  &_toolbar {
    display: flex;
    margin-top: 1rem;
    flex-wrap: wrap;

    &>* {
      margin-bottom: 1rem;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  &_buttonsContainer {
    display: flex;
    justify-content: flex-end;

    &>*:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  &_actionButton.componentButton {
    color: var(--kt-text-muted);
    background-color: var(--kt-light);

    & svg g,
    svg [fill] {
      fill: var(--kt-text-muted);
      transition: fill 0.3s;
    }

    &:hover {
      color: var(--kt-primary-active);
      background-color: var(--kt-light);

      & svg g,
      svg [fill] {
        fill: var(--kt-primary-active);
      }
    }
  }

  &_headerCell {
    vertical-align: middle;
    white-space: nowrap;
    min-width: 125px;

    &.deleteCheckbox {
      & .componentCheckbox {
        margin-right: 0;
      }
    }

    &.string {
      min-width: 200px;
    }

    &.list,
    &.link_list {
      min-width: 165px;
    }

    &.image,
    &.deleteCheckbox {
      min-width: unset;
    }
  }

  &_row {
    background: linear-gradient(to left, transparent 50%, var(--kt-input-solid-bg)
        /* var(--kt-gray-200) */
        50%) right;
    background-size: 200%;
    transition: background-position 0.3s ease-out;

    &.selected {
      background-position: left;
    }
  }

  &_cell {
    &.link {
      font-weight: 700;
      text-decoration: none;
      cursor: pointer;
    }

    &.image {
      width: calc(45px + 0.75rem * 2);
    }

    &.deleteCheckbox {
      width: calc(25px + 0.75rem);

      & .componentCheckbox {
        margin-right: 0;
      }
    }

    &.list {
      & .moduleList_cellWrapper {
        white-space: unset;
      }
    }

    &.phone {
      white-space: nowrap;
    }
  }

  &_cellData {
    &.filterable {
      cursor: pointer;
      user-select: none;
      border-bottom: 1px dashed currentColor;
    }

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

  }

  &_dateCell,
  &_cellWrapper {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 0.625rem;
    white-space: nowrap;

    &.bg-primary,
    &.bg-danger,
    &.bg-success,
    &.bg-warning,
    &.bg-dark {
      color: var(--kt-primary-inverse);
    }
  }

  &_priceCell {
    position: relative;
    height: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: var(--kt-success-light);
    color: var(--kt-success);
    border-radius: 1rem;

    & .moduleList_priceCellIcon {
      margin-left: 5px;
      margin-right: -6px;
      color: inherit;
    }

    & .moduleList_priceCellValue {
      position: relative;
      padding: 0 0.75rem;
      z-index: 2;
    }

    & .moduleList_priceCellPrevious {
      font-size: .8rem;
      position: absolute;
      top: -8px;
      right: -25px;
      height: 16px;
      text-decoration: line-through;
      background-color: inherit;
      padding: 0 0.75rem;
      border-radius: inherit;
    }

    &.negative {
      background-color: var(--kt-danger-light);
      color: var(--kt-danger);
    }

    &.hidden {
      display: none;
    }
  }

  &_linkCell {
    font-weight: 700;
    transition: color 0.3s;
    cursor: pointer;

    &:hover:not(.disabled) {
      color: var(--kt-link-color);
    }

    &.disabled {
      color: var(--kt-text-muted);
    }
  }

  &_colorCell {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 45px;
  }

  & .moduleList_emptyTable {
    text-align: center;
    font-size: 20px;
    color: var(--kt-text-muted);
    height: 200px;
  }

  &_cellImageContainer {
    display: inline-block;
    width: 45px;
    height: 45px;
  }

  &_cellImage {
    width: 100%;
    height: 100%;
    border-radius: 0.475rem;
    object-fit: cover;
  }

  & .infiniteScroll {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    color: var(--kt-text-muted);
    font-weight: 500;
  }

  &_sortButton {
    background-color: transparent;
    border: none;
    opacity: 0.5;

    &.sorted {
      opacity: 1;

      & .svg-icon {
        color: var(--kt-primary);

        & svg {
          transform: rotate(180deg);
        }
      }

      &.reverse {
        & .svg-icon {
          & svg {
            transform: rotate(0);
          }
        }
      }
    }

    & .svg-icon {
      transition: color 0.3s;

      svg {
        fill: currentColor;
        height: 14px;
        width: 14px;

      }
    }

    &:hover {
      & .svg-icon {
        color: var(--kt-primary);
      }
    }
  }

  &_downloaderModuleButton {
    height: 100%;
  }

  &_downloaderModuleCheckbox {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &_selectedItemsContainer {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    height: 48.33px;
    padding: 0 2.25rem;

    & .componentCheckbox {
      margin-right: 1.5rem;
    }

    & .componentButton {
      margin-left: 1em;
    }
  }

  & .table-responsive {
    position: relative;
    margin: 0 -2.25rem;

    & table {

      & td:first-child,
      th:first-child {
        padding-left: 2.25rem;
      }

      & td:last-child,
      th:last-child {
        padding-right: 2.25rem;
      }
    }


    &.selectedItems {
      & thead th {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}

.pagination {
  &_container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    min-height: 38px;
  }

  &_limitContainer,
  &_details,
  &_pages {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_details {
    color: var(--kt-text-muted);
    font-weight: 500;
    flex-grow: 1;
  }

  &_limit {
    &__control {
      height: 100%;
      background-color: var(--kt-input-solid-bg) !important;
      border-color: var(--kt-input-solid-bg) !important;
      border-radius: 0.475rem !important;
      color: var(--kt-input-solid-color);
      font-size: 1.1rem;
      font-weight: 500;
      box-shadow: none !important;

      &.invalid {
        border-color: var(--kt-danger) !important;
      }
    }

    &__input-container {
      margin: 0 !important;
      padding: 0 !important;
    }

    &__single-value {
      color: inherit !important;
    }

    &__multi-value__label {
      color: inherit !important;
    }

    &__menu {
      background-color: var(--kt-card-bg) !important;
      color: var(--kt-input-solid-color);
    }

    &__indicator-separator {
      display: none;
    }
  }
}