:root {
    --moduleMessenger_containerWidth: 300px;
}

.moduleMessenger {
    &_container {
        position: fixed;
        top: calc(var(--kt-app-header-height) + 10px);
        right: calc(var(--moduleMessenger_containerWidth) * -1);
        width: var(--moduleMessenger_containerWidth);
        transition: all .3s;
        box-shadow: 0 20px 40px 8px rgba(0, 0, 0, .25);

        &.active {
            right: 30px;
        }

        &.card {

            & .card-header {
                justify-content: center;
                align-items: center;
                background-color: #1e1e2d;
                color: var(--kt-text-white);

                & .card-title {
                    display: block;
                    max-width: 150px;
                    font-size: 1.25rem;
                    color: inherit;
                    overflow: hidden;
                    text-wrap: nowrap;
                    text-overflow: ellipsis;
                }
            }

            & .card-body {
                position: relative;
                padding: 0;
                height: 350px;
                overflow: hidden;
            }

            & .card-footer {
                padding: .75rem;
                overflow: hidden;
                transition: all .5s;
                opacity: 1;
                height: 117px;

                &.hidden {
                    opacity: 0;
                    padding: 0 .75rem;
                    height: 0;
                }
            }
        }
    }

    &_chatsContainer {
        position: relative;
        height: 100%;
        overflow: auto;
        z-index: 1;
        &:has(>.data-loader) {
            overflow: hidden;
            & .moduleMessenger_chatsList {
                opacity: 0;
            }
        }
        &:has(>.moduleMessenger_emptyList) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &_chatsList {
        padding: 0;
        margin: 0;
        list-style-type: none;
        opacity: 1;
        transition: opacity .5s;
    }

    &_chat {
        display: flex;
        user-select: none;
        cursor: pointer;
        transition: .3s;
        padding: .75rem;

        &:hover {
            background-color: var(--kt-app-bg-color);
        }
    }

    &_chatImage {
        width: 35px;
        height: 35px;
        border-radius: 4rem;
        margin-right: .75rem;
    }

    &_chatProperties {
        flex-grow: 1;
        overflow: hidden;
    }

    &_chatTitle {
        font-weight: 600;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &_chatPreview {
        color: var(--kt-text-muted);
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &_messagesContainer {
        position: absolute;
        top: 0;
        right: -100%;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-color: #fff;
        transition: right .3s;
        overflow: auto;
        padding: .75rem;

        &.active {
            right: 0;

        }

        &:has(>.data-loader) {
            overflow: hidden;
            & .moduleMessenger_messagesList {
                opacity: 0;
            }
        }

        &:has(>.moduleMessenger_emptyList) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &_messagesList {
        display: flex;
        flex-direction: column;
        transition: opacity .5s;
    }

    &_messageContainer {
        max-width: 80%;
        align-self: flex-start;
        background-color: var(--kt-app-bg-color);
        padding: .75rem;
        border-radius: .625rem;

        &:not(.income) {
            align-self: flex-end;
            background-color: #1e1e2d;
            color: var(--kt-text-white);

        }

        &:not(:last-child) {
            margin-bottom: .75rem;
        }
    }

    &_messageDate {
        font-size: .75rem;
        text-align: end;
    }

    &_messageForm {
        display: flex;
    }

    &_textarea {
        flex-grow: 1;
        margin:  0 .75rem .75rem 0;
    }

    &_submitButton {
        align-self: flex-start;
    }

    &_attachmentsList {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &_attachment {
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;

        color: var(--kt-primary);

        & .svg-icon {
            color: inherit;
            margin-right: .5rem;
        }
    }

    &_closeButton {
        position: absolute;
        top: 5;
        left: -35px;
        width: 28px;
        height: 28px;
        border-radius: 4rem;
        padding: 0;
        border: 1px solid #1e1e2d;
        background-color: var(--kt-text-white);
        color: #1e1e2d;
        box-shadow: 0 20px 40px 8px rgba(0, 0, 0, .25);
        transition: .3s;

        &:hover {
            background-color: #1e1e2d;
            color: var(--kt-text-white);
        }

        & .svg-icon {
            color: inherit;
        }

        &.hidden {
            display: none;
        }
    }

    &_returnButton {
        position: absolute;
        top: 40px;
        left: -35px;
        width: 28px;
        height: 28px;
        border-radius: 4rem;
        padding: 0;
        border: 1px solid #1e1e2d;
        background-color: var(--kt-text-white);
        color: #1e1e2d;
        box-shadow: 0 20px 40px 8px rgba(0, 0, 0, .25);
        transition: .3s;

        &:hover {
            background-color: #1e1e2d;
            color: var(--kt-text-white);
        }

        & .svg-icon {
            color: inherit;
        }

        &.hidden {
            display: none;
        }

    }

    &_filter {


        &.componentSelect {
            &__control {
                color: inherit;
                background-color: transparent !important;
                border: none !important;
                font-size: 1.3rem;
                font-weight: 600;
            }
            &__indicator {
                color: inherit;
            }
            &__menu {
                z-index: 4;
            }
        }



    }

    &_emptyList {
        color: var(--kt-text-muted);
        font-size: 1.25rem;
        user-select: none;
    }

    &_spinnerContainer {
        color: inherit;
        opacity: 0;
        user-select: none;
        transition: opacity .3s;
        margin-right: .45rem;
        &.active {
            opacity: 1;
        }
        & .splash-spinner {
            width: 1.25rem;
            height: 1.25rem;
            fill: transparent;
            & .path {
                stroke: currentColor;
                fill: unset;
            }
        }
    }
}