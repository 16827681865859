.componentButton {
    --app-btn-color: var(--kt-primary); 
    --app-btn-color-active: var(--kt-primary-active);
    --app-btn-contrastText: var(--kt-primary-inverse);
    position: relative;
    font-size: 0.925rem;
    font-weight: 600;
    color: var(--app-btn-contrastText);
    padding: calc(0.55rem + 1px) calc(1.25rem + 1px);
    background-color: var(--app-btn-color);
    white-space: nowrap;
    border: none;
    border-radius: 0.425rem;
    transition: color .3s, background-color .3s;
    z-index: 1;

    &:hover {
        background-color: var(--app-btn-color-active);
    }

    &:disabled {
        opacity: 0.5;
    }

    &.hidden {
        display: none;
    }

    &.primary {
        color: var(--kt-primary-inverse);
        background-color: var(--kt-primary);

        &:hover {
            background-color: var(--kt-primary-active);
        }
    }

    &.primary-light {
        background-color: var(--kt-primary-light);
        color: var(--kt-primary);
        border: 0;

        &:hover {
            background-color: var(--kt-primary);
            color: var(--kt-primary-inverse);
        }
    }

    &.secondary {
        color: var(--kt-secondary-inverse);
        background-color: var(--kt-secondary);

        &:hover {
            background-color: var(--kt-secondary-active);
        }
    }

    &.success {
        color: var(--kt-success-inverse);
        background-color: var(--kt-success);

        &:hover {
            background-color: var(--kt-success-active);
        }
    }

    &.danger {
        color: var(--kt-danger-inverse);
        background-color: var(--kt-danger);

        &:hover {
            background-color: var(--kt-danger-active);
        }
    }

    &.warning {
        color: var(--kt-warning-inverse);
        background-color: var(--kt-warning);

        &:hover {
            background-color: var(--kt-warning-active);
        }
    }

    &.info {
        color: var(--kt-info-inverse);
        background-color: var(--kt-info);

        &:hover {
            background-color: var(--kt-info-active);
        }
    }


    &.light {
        color: var(--kt-light-inverse);
        background-color: var(--kt-light);

        &:hover {
            background-color: var(--kt-light-active);
        }

    }

    &.icon_based {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        height: calc(1.5em + 1.1rem + 2px);
        width: calc(1.5em + 1.1rem + 2px);
    }

    &_content {
        display: flex;
        align-items: center;
        justify-content: center;

        & .svg-icon {
            display: flex;
            align-items: center;
            margin-right: 4px;
        }

        & .splash-spinner {
            margin-right: 4px;
        }
    }

    & .svg-icon {
        color: inherit;
    }

    & svg,
    svg g,
    svg [fill] {
        color: inherit;
        fill: currentColor;
    }

    &_modal {
        & .modal-content {
            background-color: var(--kt-modal-bg);
        }
    }

    &_modalTooltip {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 1rem;
        & > .componentSelect_container {
            flex-grow: 1;
        }

        & > *:not(:last-child) {
            margin-right: 1rem;
        }
    }

    &_modalButton {
        position: absolute;
        height: 30px;
        width: 50px;
        font-weight: 700;
        border: none;
        background-color: transparent;
        color: var(--kt-primary-inverse);

        &.modalMinimize {
            top: 0;
            right: 50px;
            background-color: var(--kt-primary);
            border: 1px solid var(--kt-primary);
            border-bottom-left-radius: calc(0.625rem - 1px);
            transition: 0.3s;

            &:hover {
                color: var(--kt-primary);
                background-color: transparent;
            }

        }

        &.modalClose {
            top: 0;
            right: 0;
            background-color: var(--kt-danger);
            border: 1px solid var(--kt-danger);
            border-top-right-radius: 0.4rem;
            transition: 0.3s;

            &:hover {
                color: var(--kt-danger);
                background-color: transparent;
            }
        }

    }

    &_container {
        display: flex;
        justify-content: flex-end;
        padding: 0 0.75rem;
        margin-bottom: 10px;

        &.between {
            justify-content: space-between;
        }

        &.inverse {
            justify-content: flex-start;
        }

        & .componentButton:not(:last-child) {
            margin-right: 1rem;
        }

        &.marginless {
            margin-bottom: 0;
        }

        &.paddingless {
            padding: 0;
        }
    }

    &.minimized {
        &::after {
            content: " ";
            position: absolute;
            top: 0;
            right: 0;
            height: 10px;
            width: 10px;
            border-radius: 4rem;
            transform: translate(50%, -50%);
            background-color: var(--kt-warning);
        }
    }

    & .splash-spinner {
        width: 1.15rem;
        height: 1.15rem;
        fill: transparent;
        & .path {
            stroke: currentColor;
            fill: unset;
        }
    }
}
