.componentSelect {
    &__control {
        height: 100%;
        background-color: var(--kt-input-solid-bg) !important;
        border-color: var(--kt-input-solid-bg) !important;
        border-radius: 0.475rem !important;
        color: var(--kt-input-solid-color);
        font-size: 1.1rem;
        font-weight: 500;
        box-shadow: none !important;

        &.invalid {
            border-color: var(--kt-danger) !important;
        }
    }

    &__value-container {
        padding: 0.775rem 0 0.775rem 1rem !important;

        & .componentSelect__multi-value {
            background-color: var(--kt-card-bg);
            border-radius: 0.475rem;
        }
    }

    &__input-container {
        color: inherit !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    &__multi-value {
        &__add {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 4px;
            border-radius: 2px;
            width: 22px;
            flex-shrink: 0;
            font-weight: 700;
            transition: color 0.3s, background-color 0.3s;

            &:hover {
                background-color: var(--kt-primary);
                color: var(--kt-primary-inverse);
            }
        }
    }

    &__multi-value__label,
    &__single-value {
        color: inherit !important;
        white-space: unset !important;
        text-overflow: unset !important;
    }

    &__menu {
        background-color: var(--kt-card-bg) !important;
        color: var(--kt-input-solid-color);
    }

    &__option {

        &:hover,
        &:active,
        &:focus,
        &.componentSelect__option--is-focused,
        &.componentSelect__option--is-selected {
            color: var(--kt-primary-inverse) !important;
            background-color: var(--kt-primary) !important;
        }
    }

    &__indicator-separator {
        display: none;
    }
}