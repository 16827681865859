.componentAudio {
    display: none;

    &_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 350px;
        padding: 0.5rem 0.75rem;
        border: 1px solid var(--kt-card-border-color);
        border-radius: 0.625rem;
    }

    &_mainProperties {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
    }

    &_title {
        font-size: 1.05rem;
        font-weight: 600;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &_time {
        font-size: 0.85rem;
        font-weight: 500;
    }

    &_range {
        width: 100%;
        height: 1rem;
    }

    &_toolbar {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
    }

    &_buttonsContainer {
        display: flex;
        align-items: center;
    }

    &_button {
        margin: 0 0.5rem;
        flex-shrink: 0;

        &.active .svg-icon svg {
            fill: var(--kt-primary);
        }
    }

    &_mainButton.componentButton {
        height: calc(2.25em + 1.1rem + 2px);
        width: calc(2.25em + 1.1rem + 2px);

        & .svg-icon svg {
            height: 1.725rem;
            width: 1.725rem;
        }
    }

}