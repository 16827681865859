.moduleMiniList {
    &_nativeFilter {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1rem;
        overflow-x: auto;
        padding: 0.5rem 0;
    }

    &_nativeFilterButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: .475rem;
        padding: calc(.775rem + 1px) calc(1.5rem + 1px);
        transition: color 0.3s, background-color 0.3s;
        user-select: none;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 0.5rem;
        }

        &.active,
        &:hover {
            background-color: var(--kt-primary);
            color: var(--kt-primary-inverse);

            &>* {
                color: inherit;
            }
        }
    }

    &_filtersContainer {
        width: 100%;
        min-height: 21px;
    }

    & .card-header {
        flex-direction: column;
    }

    & .card-body {
        padding: 0;
    }

    & .table-responsive {
        padding: 0 2.25rem;
        height: 220px;
    }

    & td:not(:first-child) {
        text-align: end;
    }

    &_value {
        font-weight: 600;
        user-select: none;
        cursor: pointer;

        &.float,
        &.integer {
            white-space: nowrap;
        }

        &.progressive {
            display: inline-block;
            padding: .325rem .5rem;
            border-radius: .425rem;
            white-space: nowrap;

            &.positive {
                background-color: var(--kt-success-light);
                color: var(--kt-success);

                &::before {
                    content: "\2191";
                    margin-right: 0.5rem;
                }
            }

            &.negative {
                background-color: var(--kt-danger-light);
                color: var(--kt-danger);

                &::before {
                    content: "\2193";
                    margin-right: 0.5rem;
                }
            }

            &.neutral {
                background-color: var(--kt-secondary);
                color: var(--kt-secondary-inverse);
            }
        }
    }
}