.moduleSalaryWidgets {
    height: 55px;
    width: 155px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 0.5rem;

    &_progressbarWidget {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;

        & .progress {
            width: 100%;
        }

        & .moduleSalaryWidgets_title {
            margin-bottom: 0.25rem;
        }
    }

    &_rangeWidget {
        max-width: 100%;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
    }

    &_title {
        font-size: 1.15rem;
        font-weight: 600;
    }

    &_range {
        font-size: 1.15rem;
        font-weight: 700;
        color: var(--kt-primary);
    }

    &_switchSalaryButton {
        position: absolute;
        top: -0.25rem;
        right: -0.25rem;
        background-color: transparent;
        border: none;

        &:hover {
            & .svg-icon {
                color: var(--kt-primary);
            }
        }
    }
}