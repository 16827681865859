.componentSearch {
    padding-right: 2.5rem;

    &_container {
        position: relative;
        flex-grow: 1;
    }

    &_button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 10px;
        top: 50%;
        width: 25px;
        height: 25px;
        padding: 0;
        border-radius: 45px;
        border: none;
        background-color: transparent;
        color: var(--kt-text-gray-700);
        transform: translateY(-50%);

        & .svg-icon {
            color: inherit;
        }

        & svg g [fill],
        svg g path {
            fill: currentColor;
        }
    }
}