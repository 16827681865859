
@media (min-width: 991.98px) {
    [data-app-theme=theme_1] #kt_app_body {
        & .moduleLinksBlock {
            &_block {
                &:hover {
                    bottom: 10px;
                    & .moduleLinksBlock_blockIcon {
                        color: #FF8022;
                    }
                }
            }
        }
        & .app-footer {
            background-color: var(--custom-primary-app-color);
        }
    }
}

@media (max-width: 991.98px) {
    [data-app-theme=theme_1] #kt_app_body {
        --kt-app-header-height: 70px;

        & .btn:not(.componentFilters_dropdown, .auth_button, #moduleSchedule_stepsButton)  {
            background-color: unset;
            &:hover {
                background-color: unset !important;
            }
        }

        & .app-footer {
            background-color: var(--custom-primary-app-color);
        }
    }
}