.moduleAccordion {
    &_item.card {
        &:not(:first-child) {
            & .moduleAccordion_itemTitle.card-header:not(.active) {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        &:last-child {
            & .moduleAccordion_itemTitle.card-header:not(.active) {
                border-bottom-left-radius: calc(0.625rem - 1px);
                border-bottom-right-radius: calc(0.625rem - 1px);
            }
        }
    }

    &_itemHeader.card-header {
        align-items: center;
        flex-wrap: nowrap;
        border: 1px solid var(--kt-card-border-color);
        transition: color 0.3s, background-color 0.3s, border-color 0.3s;
        cursor: pointer;
        min-height: unset;
        padding: 1rem 2.25rem;

        &:hover {
            border-color: var(--kt-primary);
            color: var(--kt-primary);
        }

        & .moduleAccordion_itemTitle.card-title {
            font-size: 1rem;
            font-weight: 600;
            color: inherit;
            margin: 0;
        }

        &.active {
            background-color: var(--kt-primary);
            border-color: var(--kt-primary);
            color: #fff;

            &~.accordion-collapse {
                & .moduleAccordion_itemBody.card-body {
                    border-color: var(--kt-primary);
                }
            }
        }
    }

    &_itemTitle.card-title {
        font-size: 1rem;
        font-weight: 600;
        flex-grow: 1;
    }

    &_itemButtons {
        display: flex;
        justify-content: space-between;

        &>*:not(:last-child) {
            margin-right: 0.5rem;
        }
    }

    &_itemBody.card-body {
        position: relative;
        overflow: auto;
        border: 1px solid var(--kt-card-border-color);
        min-height: 300px;
    }

    &_itemPreview {
        justify-content: center;
        align-items: center;


        &.default {
            display: flex;
            height: 60px;
            text-align: center;
        }

        &.image {
            display: flex;

            & img {
                max-width: 100%;
                object-fit: contain;

            }
        }

        &.pdf {
            & .react-pdf__Page {
                &:not(:last-child) {
                    margin-bottom: 1rem;
                }

                &__canvas {
                    margin: 0 auto;
                }

                &__textContent {
                    left: 50%;
                    transform: translateX(-50%);
                    border: 1px solid darkgrey;
                    box-shadow: 5px 5px 5px 1px #ccc;
                    border-radius: 5px;

                }
            }
        }
    }

    & .paginationRow {
        margin: 1rem 0 0;
        background-color: var(--kt-card-bg);
        padding: 0.25rem 1rem;
        border-radius: 0.625rem;
    }
}

.hiddenCanvasElement {
    height: 0;
    width: 0;
}