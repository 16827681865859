.moduleHeader {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem;

    &_title {
        font-size: 1.35rem;
        font-weight: 600;
        color: var(--kt-text-dark);
    }

    &_description {
        font-size: 0.95rem;
        color: var(--kt-text-muted);
    }

    &_buttons {
        display: flex;
        align-items: center;
        & .componentButton {
            &:not(:last-child) {
                margin-right: 0.5rem;
            }
        }
    }
}